
import { Privacy } from "@/api";
import { PrivacyForm, PrivacyFormCompany } from "@/api/types";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import PageNotFound from "@/views/PageNotFound.vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    PageNotFound
  },
  mixins: [PhoneCodes]
})
export default class PrivacyFormPage extends Vue {
  purposeTypeValue = 0;
  privacyAgree = false;
  formData: PrivacyForm = {
    name: "",
    surname: "",
    passport: undefined,
    birthday: moment()
      .add(1, "days")
      .format("YYYY-MM-DD"),
    genderId: undefined,
    phone: "",
    companyId: undefined,
    quoteId: undefined
  };
  formDataCompany: PrivacyFormCompany = {
    quoteId: undefined,
    delegates: []
  };
  blankTabItems: any = [];
  menuPrivate = false;
  menuCompany: any = [];
  tab = 0;
  companyId: number | string | undefined = undefined;
  quoteData: any = {};
  wasFormSubmitted = false;
  wasDeclined = false;
  doesQuoteExist = false;
  isLoading = false;
  purposeTypes = [
    {
      id: 0,
      name: "Company"
    },
    {
      id: 1,
      name: "Privat"
    }
  ];
  genders = [
    {
      name: "Male",
      id: 1
    },
    {
      name: "Female",
      id: 2
    }
  ];
  currentDate = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");
  async created() {
    try {
      this.isLoading = true;
      const quoteId = +this.$route.params.id;
      const quoteData: any = await Privacy.getQuoteData(quoteId);
      this.doesQuoteExist = true;
      this.isLoading = false;
      this.companyId = quoteData.companyId;
      this.quoteData = quoteData;
      this.purposeTypeValue = (await quoteData.companyType) ? 0 : 1;
      if (quoteData.companyType) {
        const delegatesEmpty = [];
        for (let i = 0; i < quoteData.qtyDelegates; i++) {
          delegatesEmpty.push({
            name: ``,
            surname: ``,
            passport: undefined,
            birthday: moment()
              .add(1, "days")
              .format("YYYY-MM-DD"),
            genderId: undefined,
            phone: "",
            companyId: undefined
          });
          this.menuCompany.push(false);
        }
        this.formDataCompany.delegates = [...delegatesEmpty];
        this.formDataCompany.quoteId = quoteData.id;
        this.blankTabItems = [...delegatesEmpty];
      } else {
        this.quoteData = quoteData;
        this.formData.name = quoteData.clientName.split(" ")[0];
        this.formData.surname = quoteData.clientName.split(" ")[1];
        this.formData.birthday = moment(quoteData.desiredDate).format(
          "YYYY-MM-DD"
        );
        this.formData.quoteId = quoteData.id;
      }
    } catch (e) {
      const err = e as any;
      this.isLoading = false;
      this.doesQuoteExist = false;
      await this.$error(err.errors);
    }
  }
  get formattedPrivateDate() {
    return moment(this.formData.birthday).format("DD/MM/YYYY");
  }
  formattedCompanyDate(currentTab: number | any) {
    return moment(currentTab.birthday).format("DD/MM/YYYY");
  }
  async submitAccept() {
    try {
      if (this.purposeTypeValue === 1) {
        const valid: any = await (this.$refs.observer as Vue & {
          validate: () => boolean;
        }).validate();

        if (valid) {
          await Privacy.accept(this.companyId, this.formData);
          await this.$success(
            "<strong>Success!</strong>Training course quote have been successfully submitted!"
          );
          this.wasFormSubmitted = true;
        }
      } else {
        const validCompany: any = await (this.$refs.observerCompany as Vue & {
          validate: () => boolean;
        }).validate();

        if (validCompany) {
          const formDataToSend: any = {
            delegates: this.formDataCompany.delegates.filter(
              (delegate: any) => {
                for (const key in delegate) {
                  if (delegate && delegate[key] && key !== "birthday") {
                    return delegate;
                  }
                }
              }
            ),
            quoteId: +this.$route.params.id
          };
          await Privacy.accept(this.companyId, formDataToSend);
          await this.$success(
            "<strong>Success!</strong>Training course quote have been successfully submitted!"
          );
          this.wasFormSubmitted = true;
        }
      }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async submitDecline() {
    try {
      await Privacy.decline(this.quoteData.id);
      await this.$success(
        "<strong>Success!</strong>Training course quote have been successfully declined!"
      );
      this.wasFormSubmitted = true;
      this.wasDeclined = true;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  setDateOfBirth(isCompany: boolean, delegateNumber = 0) {
    if (
      isCompany &&
      this.formDataCompany &&
      this.formDataCompany.delegates &&
      this.formDataCompany.delegates[delegateNumber] &&
      this.formDataCompany.delegates[delegateNumber].passport
    ) {
      const passportId = this.formDataCompany.delegates[delegateNumber]
        .passport;
      if (
        passportId &&
        passportId.toString().length >= 6 &&
        !isNaN(+passportId)
      ) {
        const splitedId: any = passportId.toString().match(/.{1,2}/g);
        const year =
          splitedId && splitedId[0] && Number(splitedId[0]) > 15
            ? "19" + splitedId[0]
            : "20" + splitedId[0];
        const month =
          splitedId && splitedId[1] && Number(splitedId[1]) > 12
            ? 12
            : splitedId[1];
        const day =
          splitedId && splitedId[2] && Number(splitedId[2]) > 31
            ? 31
            : splitedId[2];
        const preFormattedDate = `${year}-${month}-${day}`;

        this.formDataCompany.delegates[
          delegateNumber
        ].birthday = preFormattedDate;
      }
    } else {
      const passportId = this.formData.passport;
      if (
        passportId &&
        passportId.toString().length >= 6 &&
        !isNaN(+passportId)
      ) {
        if (passportId && passportId.toString().length >= 6) {
          const splitedId: any = passportId.toString().match(/.{1,2}/g);
          const year =
            splitedId && splitedId[0] && Number(splitedId[0]) > 15
              ? "19" + splitedId[0]
              : "20" + splitedId[0];
          const month =
            splitedId && splitedId[1] && Number(splitedId[1]) > 12
              ? 12
              : splitedId[1];
          const day =
            splitedId && splitedId[2] && Number(splitedId[2]) > 31
              ? 31
              : splitedId[2];

          const preFormattedDate = `${year}-${month}-${day}`;

          this.formData.birthday = preFormattedDate;
        }
      }
    }
  }
}
