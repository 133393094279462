import { render, staticRenderFns } from "./PrivacyFormPage.vue?vue&type=template&id=63d0cba6&scoped=true&"
import script from "./PrivacyFormPage.vue?vue&type=script&lang=ts&"
export * from "./PrivacyFormPage.vue?vue&type=script&lang=ts&"
import style0 from "./PrivacyFormPage.vue?vue&type=style&index=0&id=63d0cba6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d0cba6",
  null
  
)

export default component.exports